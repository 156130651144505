<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="게시기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="게시기간(To)"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              :options="codes.useTypeFixed"
              placeholder="모아보기"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px; margin-right:50px;"
            />
            <v-select
              :options="codes.useTypeFixed"
              placeholder="처방 팝업 알림"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px; margin-right:30px;"
            />
            <v-select
              v-model="query.surveyStatus"
              :options="codes.surveyStatus"
              placeholder="설문 상태"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px;"
            />
            <b-form-input
              v-model="query.filterStr"
              placeholder="제목 검색"
              class="el-def"
              style="width:300px;"
              @keyup.enter="refetchData()"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              투표지 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refCommentSurveyTable"
        primary-key="id"
        :items="commentSurveyList"
        :fields="tableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedSurvey"
      >
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(startDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
            ~
            {{ data.item.endDate | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
        <template #cell(liveStatusName)="data">
          <span class="text-nowrap">
            참여 {{ data.value }}
          </span>
        </template>
      </b-table>
    </b-card>
    <b-card
      class="mt-2"
    >
      <validation-observer ref="formRules">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <feather-icon
              icon="EditIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              투표지 상세
            </h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-primary"
              @click.prevent="resetCommentSurveyData()"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>신규 투표지 등록</span>
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click.prevent="saveCommentSurvey()"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span>투표지 저장</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-1"
              @click.prevent="removeCommentSurvey()"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span>투표지 삭제</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="제목"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="제목"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model="commentSurveyData.title"
                  :state="errors.length > 0 ? false : null"
                  maxlength="90"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center"
          >
            <b-form-group
              label="게시여부"
              label-for="is-use"
            >
              <b-form-radio-group
                id="is-use"
                v-model="commentSurveyData.isUse"
                :options="codes.useTypeFixed"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="참여가능기간(Fr)"
              label-for="start-date"
            >
              <validation-provider
                #default="{ errors }"
                name="참여가능기간(Fr)"
                :rules="{ required: true, before: commentSurveyData.endDate }"
              >
                <b-form-datepicker
                  id="start-date"
                  v-model="commentSurveyData.startDate"
                  placeholder="참여가능기간(Fr)"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="참여가능기간(To)"
              label-for="end-date"
            >
              <validation-provider
                #default="{ errors }"
                name="참여가능기간(To)"
                :rules="{ required: true, after: commentSurveyData.startDate }"
              >
                <b-form-datepicker
                  id="end-date"
                  v-model="commentSurveyData.endDate"
                  placeholder="참여가능기간(To)"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div
          class="mt-1 rounded p-1"
          style="position: relative; background-color: rgba(128, 128, 128, 0.2);"
        >
          <div
            v-show="commentSurveyData.isAnswer"
            style="color: #ea5455"
          >
            참여자가 있어 문항 정보를 수정할 수 없습니다.
          </div>
          <div
            v-for="(element, index) in commentSurveyElements"
            :id="`element${element.id}`"
            :key="element.id"
            ref="element"
          >
            <div
              class="mt-1 ml-1 mr-1 bg-primary rounded-top"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="2"
                >
                  <h4
                    class="font-weight-bolder mb-0 p-1"
                    style="color: white;"
                  >
                    {{ `문항` }}
                    <!-- {{ `문항 ${(index + 1)}` }} -->
                  </h4>
                </b-col>
                <b-col
                  cols="12"
                  md="10"
                  class="text-right pr-2"
                  style="align-content: center;"
                >
                  <b-button
                    v-if="commentSurveyElements.length > 1 && !commentSurveyData.isAnswer"
                    class="btn-icon"
                    variant="flat-dark"
                    style="color: white;"
                    @click="removeElement(element.id)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>문항 삭제</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div
              class="mb-1 ml-1 mr-1 rounded-bottom p-1"
              style="background-color: rgba(128,128,128, 0.7);"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="10"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="문항 제목"
                    rules="required"
                  >

                    <b-form-input
                      v-model="element.title"
                      :state="errors.length > 0 ? false : null"
                      :disabled="commentSurveyData.isAnswer"
                      maxlength="90"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="pl-0"
                >
                  <b-button
                    variant="primary"
                    style="width: 100%;"
                    :disabled="commentSurveyData.isAnswer"
                    @click.prevent="addElementChoice(element.id)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span>선택지 추가</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row
                v-for="(choice, dIdx) in element.commentSurveyElementChoices"
                :id="`element${element.id}-choice${choice.id}`"
                :key="element.id + '-' + choice.id"
                ref="elementChoice"
                style="margin-top: 5px"
              >
                <b-col
                  cols="12"
                  md="10"
                  class="d-flex justify-content-center align-items-center pl-4 pr-0"
                >

                  <b-avatar
                    variant="primary"
                    :style="`margin-right: 10px; cursor: default; background-color:${choice.colors.hex} !important;`"
                    :text="choice.seq.toString()"
                    class="align-baseline"
                    button
                  />

                  <b-input-group
                    class="input-group-merge"
                    size="sm"
                    style="padding-right: 14px;"
                  >

                    <b-form-input
                      v-model="choice.description"
                      :disabled="commentSurveyData.isAnswer"
                      size="sm"
                      style="width: 100%; height: 35px;"
                      maxlength="90"
                    />
                  </b-input-group>

                  <b-button
                    :disabled="commentSurveyData.isAnswer"
                    size="sm"
                    :style="`height: 100%; margin-right: 14px; background-color:${choice.colors.hex} !important;`"
                    @focus="showPicker(element.id, choice.id)"
                    @blur="hidePicker(element.id, choice.id)"
                  >
                    <feather-icon
                      icon="DropletIcon"
                    />
                  </b-button>

                  <!-- <material v-model="colors" />
                  <compact v-model="colors" />
                  <slider v-model="colors" />
                  <photoshop v-model="colors" /> -->

                  <div
                    v-if="choice.isPickerVisible"
                    style=" position: absolute; left: 100%; z-index: 1000;"
                    @mousedown.prevent
                  >
                    <!-- <Chrome v-model="choice.colors" /> -->
                    <!-- <Swatches v-model="choice.colors" /> -->
                    <Sketch
                      v-model="choice.colors"
                      :preset-colors="colorPreset"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="d-flex justify-content-center align-items-center pl-0 pr-4"
                >

                  <b-button
                    v-if="element.commentSurveyElementChoices.length > 2"
                    :disabled="commentSurveyData.isAnswer"
                    variant="danger"
                    size="sm"
                    style="width: 100%; height: 100%;"
                    @click="removeElementChoice(element.id, choice.id)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-25"
                    />
                    <span>선택지 삭제</span>
                  </b-button>
                </b-col>
              </b-row>

            </div>
          </div>

          <div
            v-if="false"
            :disabled="commentSurveyData.isAnswer"
            class="p-1"
          >
            <b-row
              class="d-flex justify-content-center align-items-center"
            >
              <b-col
                cols="12"
                md="9"
              >

                <b-button
                  variant="primary"
                  style="width: 100%;"
                  @click.prevent="addElement()"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>신규 문항 추가</span>
                </b-button>

              </b-col>

            </b-row>
          </div>

        </div>
      </validation-observer>
    </b-card>

  </div>

</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import {
  reactive, ref, onMounted, getCurrentInstance,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, length } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { errorFormatter } from '@core/utils/filter'
import {
  BInputGroup, BAvatar,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import { Sketch } from 'vue-color'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BAvatar,
    BButton,
    BFormInput,
    Sketch,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
      resetCommentSurveyData()
    })
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // const defaultColorHex = '#999999'
    const defaultColorHex = '#836AF9'
    const colorPreset = [
      '#3267E9',
      '#FF9F22',
      '#5ACB00',
      '#cb5b00',
      '#fb91f4',
      '#836AF9',
    ]

    const commentSurveyInitState = {
      id: null,
      title: null,
      isUse: true,
      startDate: null,
      endDate: null,
      isAnswer: false,
    }

    const commentSurveyData = ref({ ...commentSurveyInitState })

    const commentSurveyElements = reactive([])

    const resetCommentSurveyData = () => {
      refs.formRules.reset() // 유효성 검사 초기화
      refCommentSurveyTable.value.clearSelected()

      Object.assign(commentSurveyData.value, commentSurveyInitState)

      commentSurveyElements.splice(0, commentSurveyElements.length)
      if (commentSurveyElements.length < 1) {
        addElement() // 기본 문항 1개 필요
      }
    }

    // Set Codes
    const codes = ref({
      useTypeFixed: [
        { label: '사용', code: true },
        { label: '사용안함', code: false },
      ],
      surveyStatus: [],
    })

    const fetchCodes = () => {
      axios.get('/fa/comment-survey/codes')
        .then(rs => {
          const {
            surveyLiveStatus,
          } = rs.data
          codes.value.surveyStatus = surveyLiveStatus

          refetchData()
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    // Set Codes End.

    // Query Data
    const query = ref({
      startDate: dayjs().add(-6, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().add(2, 'month').format('YYYY-MM-DD'),
      surveyStatus: null,
      filterStr: null,
    })
    // Query Data End.

    // Main Table Config
    const refCommentSurveyTable = ref(null)
    const commentSurveyList = ref([])
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: 'id', label: 'Id', sortable: true, thStyle: { width: '10%' },
      },
      { key: 'title', label: '제목', sortable: true },
      {
        key: 'startDate', label: '참여가능기간', sortable: true, thStyle: { width: '20%' },
      },
      {
        key: 'liveStatusName', label: '상태', sortable: true, thStyle: { width: '10%' },
      },
      // {
      //   key: 'createDate', label: '생성일', sortable: true, thStyle: { width: '10%' },
      // },
    ]
    const refetchData = () => {
      fetchCommentSurveys()

      refCommentSurveyTable.value.refresh()
    }
    const onRowSelectedSurvey = items => {
      const item = items[0]
      if (item) {
        fetchSurvey(item.id)
        fetchCommentSurveyElements(item.id)
      }
    }
    // Main Table Config End.

    const fetchSurvey = id => {
      axios.get(`/fa/comment-survey/${id}`)
        .then(rs => {
          const { data } = rs
          // data.title = data.title
          commentSurveyData.value = data
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchCommentSurveys = () => {
      axios.post('/fa/comment-survey/list', {
        search: {
          startDate: query.value.startDate ? `${query.value.startDate}T00:00:00` : null,
          endDate: query.value.endDate ? `${query.value.endDate}T23:59:59` : null,
          surveyStatus: query.value.surveyStatus,
          filterStr: query.value.filterStr,
        },
        sort: {
          predicate: 'id',
          reverse: true,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          commentSurveyList.value = items
          totalRecords.value = totalRecord
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchCommentSurveyElements = id => {
      axios.get(`/fa/comment-survey/elements/${id}/`)
        .then(rs => {
          const resultData = rs.data
          for (let i = 0; i < resultData.length; i += 1) {
            for (let j = 0; j < resultData[i].commentSurveyElementChoices.length; j += 1) {
              const choice = resultData[i].commentSurveyElementChoices[j]
              choice.colors = { hex: choice.colorCode }
              choice.isPickerVisible = false
            }
          }

          commentSurveyElements.splice(0, commentSurveyElements.length, ...resultData)
        })
        .catch(() => {
          commentSurveyElements.splice(0, commentSurveyElements.length) // 빈 배열로 초기화하여 반환
        })
    }

    const addElement = () => {
      let lastElementSeq = 0
      if (commentSurveyElements.length > 0) {
        lastElementSeq = commentSurveyElements.reduce((maxElement, current) => ((current.seq > maxElement.seq) ? current : maxElement)).seq
      }

      commentSurveyElements.push({
        id: lastElementSeq + 1,
        commentSurveyId: commentSurveyData.value.id ?? 0,
        seq: lastElementSeq + 1,
        title: null,
        commentSurveyElementChoices: [
          {
            id: 1,
            commentSurveyElementId: lastElementSeq + 1,
            seq: 1,
            description: null,
            colors: { hex: defaultColorHex },
            isPickerVisible: false,
          },
          {
            id: 2,
            commentSurveyElementId: lastElementSeq + 1,
            seq: 2,
            description: null,
            colors: { hex: defaultColorHex },
            isPickerVisible: false,
          },
        ],
      })
    }

    const addElementChoice = elementId => {
      const targetElementChoices = commentSurveyElements.find(e => e.id === elementId).commentSurveyElementChoices

      let lastChoiceSeq = 0
      if (targetElementChoices.length > 0) {
        lastChoiceSeq = targetElementChoices.reduce((maxChoice, current) => ((current.seq > maxChoice.seq) ? current : maxChoice)).seq
      }

      targetElementChoices.push({
        id: lastChoiceSeq + 1,
        commentSurveyElementId: elementId,
        seq: lastChoiceSeq + 1,
        description: null,
        colors: { hex: defaultColorHex },
        isPickerVisible: false,
      })
    }

    const removeElement = elementId => {
      const targetElementIndex = commentSurveyElements.findIndex(e => e.id === elementId)
      if (targetElementIndex !== -1 && targetElementIndex !== undefined) {
        commentSurveyElements.splice(targetElementIndex, 1) // 문항 제거

        // 시퀀스 재할당
        for (let i = 0; i < commentSurveyElements.length; i += 1) {
          commentSurveyElements[i].id = i + 1
          commentSurveyElements[i].seq = i + 1
        }
      }
    }

    const removeElementChoice = (elementId, choiceId) => {
      const targetElement = commentSurveyElements.find(e => e.id === elementId)
      const targetElementChoiceIndex = targetElement?.commentSurveyElementChoices.findIndex(c => c.id === choiceId)
      if (targetElementChoiceIndex !== -1 && targetElementChoiceIndex !== undefined) {
        const targetElementChoices = targetElement.commentSurveyElementChoices

        targetElementChoices.splice(targetElementChoiceIndex, 1) // 선택지 제거

        // 시퀀스 재할당
        for (let i = 0; i < targetElementChoices.length; i += 1) {
          targetElementChoices[i].id = i + 1
          targetElementChoices[i].seq = i + 1
        }
      }
    }

    const showPicker = (elementId, choiceId) => {
      const targetElementChoice = commentSurveyElements.find(e => e.id === elementId)?.commentSurveyElementChoices.find(c => c.id === choiceId)
      if (targetElementChoice !== undefined) {
        targetElementChoice.isPickerVisible = true
      }
    }

    const hidePicker = (elementId, choiceId) => {
      const targetElementChoice = commentSurveyElements.find(e => e.id === elementId)?.commentSurveyElementChoices.find(c => c.id === choiceId)
      if (targetElementChoice !== undefined) {
        targetElementChoice.isPickerVisible = false
      }
    }

    const updateCommentSurvey = () => {
      const commentSurveyInfo = {
        title: commentSurveyData.value.title,
        startDate: commentSurveyData.value.startDate,
        endDate: commentSurveyData.value.endDate,
        isUse: commentSurveyData.value.isUse,
        commentSurveyElements,
      }

      // 갱신
      axios.patch(`/fa/comment-survey/update/${commentSurveyData.value.id}`, commentSurveyInfo)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Comment Survey 데이터 변경 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
          resetCommentSurveyData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const createCommentSurvey = () => {
      const commentSurveyInfo = {
        title: commentSurveyData.value.title,
        startDate: commentSurveyData.value.startDate,
        endDate: commentSurveyData.value.endDate,
        isUse: commentSurveyData.value.isUse,
        commentSurveyElements,
      }

      // 신규 생성
      axios.put(`/fa/comment-survey/new`, commentSurveyInfo)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '신규 Comment Survey 등록 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
          resetCommentSurveyData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const saveCommentSurvey = () => {
      const validPromise = refs.formRules.validate()

      validPromise.then(valid => {
        if (valid) {
          // 유효성 통과한 경우만 저장
          // 색상코드 업데이트
          for (let i = 0; i < commentSurveyElements.length; i += 1) {
            for (let j = 0; j < commentSurveyElements[i].commentSurveyElementChoices.length; j += 1) {
              const choice = commentSurveyElements[i].commentSurveyElementChoices[j]
              choice.colorCode = choice.colors.hex
            }
          }

          if (commentSurveyData.value.id < 10000) {
            createCommentSurvey()
          } else if (commentSurveyData.value.id >= 10000) {
            updateCommentSurvey()
          }
        }
      })
    }

    const removeCommentSurvey = () => {
      // 삭제
      axios.delete(`/fa/comment-survey/${commentSurveyData.value.id}`)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 삭제 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
          resetCommentSurveyData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '삭제하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    return {
      query,
      codes,
      commentSurveyData,
      commentSurveyList,
      tableColumns,
      length,
      required,
      refetchData,
      totalRecords,
      fetchCommentSurveys,
      resetCommentSurveyData,
      onRowSelectedSurvey,
      commentSurveyElements,
      refCommentSurveyTable,
      addElement,
      addElementChoice,
      removeElement,
      removeElementChoice,
      showPicker,
      hidePicker,
      updateCommentSurvey,
      createCommentSurvey,
      saveCommentSurvey,
      removeCommentSurvey,
      colorPreset,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
